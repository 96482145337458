import Typography from '@mui/material/Typography';
import './recording-progress-bar.scss';
import { FC } from 'react';

type RecordingProgressBarProps = {
  progress: number;
};

const RecordingProgressBar: FC<RecordingProgressBarProps> = ({ progress }) => {
  return (
    <div className="recording-progress-bar-container">
      <div className="progress-bar-background">
        <div
          className="uploading-progress"
          style={{
            width: `${Math.min(Math.max(progress, 0), 100)}%`, // Ensure progress stays between 0 and 100
          }}
        />
      </div>
      <Typography className="progress-text">
        {Math.min(Math.max(progress, 0), 100).toFixed(1)}%
      </Typography>
    </div>
  );
};

export default RecordingProgressBar;
