import dayjs from 'dayjs';

const extractCameraType = (path: string) => {
  // const parts = path.split('\\');
  // const type = parts[parts.length - 2];
  // return type.charAt(0).toUpperCase() + type.slice(1);
  return 'Trolley';
};

const extractVideoLength = (path: string) => {
  // console.log('path', path);
  const dateStr = path.match(/(\d{4}-\d{2}-\d{2}-\d{2}h\d{2}m\d{2}s)/)?.[0];
  if (dateStr) {
    const time = dateStr
      .split('-')
      .slice(3)
      .join('-')
      .replace(/h|m/g, ':')
      .replace('s', '');
    const date = dateStr.split('-').slice(0, 3).join('-');
    const dateDayjs = dayjs(date);

    return { date: dateDayjs, time };
  }
  return { date: '', time: '' };
};

export { extractCameraType, extractVideoLength };
