import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ContentStack, Loading } from '../../../components/styled';
import AddVideoRecordingsModal from './components/AddVideoRecordingsModal';
import Recordings from './components/Recordings';
import { extractCameraType, extractVideoLength } from './media-utils';
import { socket } from '../../../websocket-client/websocket-client';
import * as storageHelper from '../../../utils/storageHelper';
import { ULTRAWIS_SELECTED_CRANE } from '../../../variables/constants';

interface RecordingInProgress {
  recording: any;
  progress: number;
}

const Media = ({
  videRecordingsData,
  videoRecordingsIsLoading,
  videoRecordingsError,
  videoRecordingsRefetch,
}: any) => {
  const [openAddCraneMedia, setOpenAddCraneMedia] = useState(false);
  const [isCraneConnected, setIsCraneConnected] = useState(false);
  const [requestedRecordings, setRequestedRecordings] = useState<any>([]);
  const sm = useMediaQuery('(max-width: 600px)');
  const selectedCrane = storageHelper.getItem(ULTRAWIS_SELECTED_CRANE);

  const paddingLeft = '30px';

  useEffect(() => {
    if (videoRecordingsError) {
      setIsCraneConnected(false);
      setRequestedRecordings([]);
      return;
    }

    if (!videRecordingsData || !videRecordingsData.recordings) {
      return;
    }

    const newVideoRecordings = videRecordingsData.recordings
      .map((item: any) => {
        const cameraType = extractCameraType(item.path);
        const { date, time } = extractVideoLength(item.path);
        if (!date || !time) return;

        return {
          ...item,
          cameraType,
          date,
          time,
        };
      })
      .sort((a: any, b: any) => b.date - a.date);

    const groupedRecordings = newVideoRecordings.reduce(
      (acc: any, item: any) => {
        const dateStr = item.date.format('YYYY-MM-DD');
        if (!acc[dateStr]) {
          acc[dateStr] = [];
        }
        acc[dateStr].push(item);
        return acc;
      },
      {},
    );

    const requestedGroupedRecordings = Object.keys(groupedRecordings).reduce(
      (acc: any, date: string) => {
        const recordings = groupedRecordings[date].filter(
          (item: any) => item.isRequested,
        );
        if (recordings.length > 0) {
          return [
            ...acc,
            {
              date,
              recordings,
            },
          ];
        }
        return acc;
      },
      [],
    );

    setRequestedRecordings(requestedGroupedRecordings);
    setIsCraneConnected(videRecordingsData.crane.isConnected);
  }, [videRecordingsData, videoRecordingsError]);

  // Handle real-time updates
  useEffect(() => {
    socket.connect();

    socket.emit('crane-video-upload-request', {
      craneId: selectedCrane,
    });

    socket.on('crane-video-upload-response', (data) => {
      const { recording: inProgressRecording, status } = data;
      // console.log('data', JSON.stringify(data, null, 4));
      const recordingId = inProgressRecording.id;
      const { percentCompleted: progress, state } = status;
      const signedUrl = inProgressRecording.signedUrl;
      const { date: dayjsDate, time } = extractVideoLength(
        inProgressRecording.path,
      );
      const date = dayjs.isDayjs(dayjsDate)
        ? dayjsDate.format('YYYY-MM-DD')
        : dayjsDate;

      setRequestedRecordings((prevState: any) => {
        // Check if the date group exists
        const groupIndex = prevState.findIndex(
          (group: any) => group.date === date,
        );

        if (groupIndex !== -1) {
          // Update existing group
          const updatedGroups = [...prevState];
          const group = updatedGroups[groupIndex];

          // Check if the recording exists in the group
          const recordingIndex = group.recordings.findIndex(
            (recording: any) => recording.id === recordingId,
          );
          const isFailed = ![
            0, // VIDEO_RECORDING_UPLOAD_STATE_IN_PROGRESS
            1, // VIDEO_RECORDING_UPLOAD_STATE_IN_COMPLETED
            'VIDEO_RECORDING_UPLOAD_STATE_IN_COMPLETED',
            'VIDEO_RECORDING_UPLOAD_STATE_IN_PROGRESS',
          ].includes(state); // Since protobuf enum values are not consistent with the API response we need to check for both number and string values

          if (recordingIndex !== -1) {
            // Update existing recording
            if (state === 'VIDEO_RECORDING_UPLOAD_STATE_IN_COMPLETED') {
              group.recordings[recordingIndex] = {
                ...group.recordings[recordingIndex],
                isRequested: true,
                isFailed: false,
                isUploaded: true,
                progress: 100,
                signedUrl: signedUrl,
              };
            } else {
              group.recordings[recordingIndex] = {
                ...group.recordings[recordingIndex],
                isRequested: true,
                isFailed: isFailed,
                isUploaded: Math.round(progress) === 100,
                progress,
                signedUrl:
                  Math.round(progress) === 100 && signedUrl ? signedUrl : '',
              };
            }
          } else {
            // Add new recording to the group
            group.recordings.push({
              ...inProgressRecording,
              cameraType: extractCameraType(inProgressRecording.path),
              time,
              progress,
              isRequested: true,
              isUploaded: false,
              isFailed: isFailed,
              signedUrl: '',
            });
          }

          updatedGroups[groupIndex] = group;
          return updatedGroups;
        } else {
          // Create a new group if it doesn't exist
          return [
            ...prevState,
            {
              date,
              recordings: [
                {
                  ...inProgressRecording,
                  cameraType: extractCameraType(inProgressRecording.path),
                  time,
                  progress,
                  isRequested: true,
                  isUploaded: false,
                  signedUrl: '',
                },
              ],
            },
          ];
        }
      });
    });
    return () => {
      socket.disconnect();
      socket.off('crane-video-upload-response');
    };
  }, [selectedCrane]);

  const onVideoRecordingRequestSuccess = () => {
    setOpenAddCraneMedia(false);
    videoRecordingsRefetch();
  };

  const onVideoRecordingRequestCancel = () => {
    setOpenAddCraneMedia(false);
  };

  return (
    <Grid container flex={1}>
      <ContentStack
        paperFlexDirection="column"
        style={{
          marginTop: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {openAddCraneMedia && (
          <AddVideoRecordingsModal
            onClose={onVideoRecordingRequestCancel}
            onSuccess={onVideoRecordingRequestSuccess}
          />
        )}

        {videoRecordingsIsLoading && !openAddCraneMedia ? (
          <Loading />
        ) : (
          <Box sx={{ paddingLeft: '20px' }}>
            <Button
              variant="contained"
              onClick={() => setOpenAddCraneMedia(true)}
              endIcon={<AddIcon />}
              sx={{
                width: sm ? '100%' : '240px',
                paddingTop: '18px',
                marginLeft: paddingLeft,
                paddingBottom: '18px',
                alignSelf: 'start',
              }}
            >
              Upload recordings
            </Button>

            {requestedRecordings.length === 0 ? (
              <Typography
                variant="h2Medium"
                color="#878a99"
                height={'100%'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {'No data to display'}
              </Typography>
            ) : (
              <Box
                sx={{
                  paddingTop: paddingLeft,
                  paddingLeft: '30px',
                  width: '70%',
                }}
              >
                <Recordings
                  recordings={requestedRecordings}
                  videoRecordingsRefetch={videoRecordingsRefetch}
                  isCraneConnected={isCraneConnected}
                />
              </Box>
            )}
          </Box>
        )}
      </ContentStack>
    </Grid>
  );
};

export default Media;
