import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { API_PATH } from '../../../../variables/endpoints';
import VideoRecordingPlayer from './VideoRecordingPlayer';
import { TOKEN } from '../../../../variables/constants';
import RecordingProgressBar from './RecordingProgressBar/RecordingProgressBar';
import { usePutRequestCraneVideoRecordings } from '../../../../queries';
import { useQueryClient } from 'react-query';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';

const Recording = ({
  recording,
  selectedRecordings = [],
  setSelectedRecordings = () => {},
  progress,
  videoRecordingsRefetch,
  isCraneConnected,
}: any) => {
  const { isFailed, isUploaded, isRequested } = recording;

  const selectedCrane = useAppSelector(
    (state: any) => state.projectCrane.Crane,
  );
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [craneIsOffline, setCraneIsOffline] = useState(false);
  const [showCraneIsOfflineMessage, setShowCraneIsOfflineMessage] =
    useState(false);
  const sm = useMediaQuery('(max-width: 600px)');
  const queryClient = useQueryClient();

  useEffect(() => {
    setCraneIsOffline(!isCraneConnected);
  }, [isCraneConnected]);

  const handleDownload = () => {
    setIsDownloading(true);
    const craneId = selectedCrane;
    let accessToken = localStorage.getItem(TOKEN) || '';
    accessToken = accessToken?.replace(/['"]+/g, '');
    const path = recording.path;
    const url = `${API_PATH}/crane/${craneId}/video-recordings/download`;
    const params = new URLSearchParams({ token: accessToken, path }).toString();

    setTimeout(() => {
      setIsDownloading(false);
      window.location.href = `${url}?${params}`;
    }, 500);
  };

  const { mutate, isLoading, error } = usePutRequestCraneVideoRecordings({
    onSuccess: (res: any) => {
      if (res.data.status === 'success') {
        videoRecordingsRefetch();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('PutRequestCraneVideoRecordings');
    },
    onError: (err: any) => {
      const isConnected = err?.response?.data?.isConnected;
      if (isConnected === false) {
        setCraneIsOffline(true);
        setShowCraneIsOfflineMessage(true);
        setTimeout(() => {
          setShowCraneIsOfflineMessage(false);
        }, 3000);
      }
    },
  });

  const handleTryAgain = async () => {
    mutate({
      craneId: selectedCrane,
      paths: [recording.path],
    });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginTop: '10px' }}
      sx={{
        padding: sm ? '10px' : '10px 40px',
        borderRadius: 2,
        border: 'solid 1px',
        flex: 1,
        borderColor: '#e9ebec',
        width: '100%',
      }}
    >
      {openVideoPlayer && (
        <VideoRecordingPlayer
          recording={recording}
          onClose={() => setOpenVideoPlayer(false)}
        />
      )}
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        width={'30%'}
      >
        <Typography sx={{ fontSize: '16px' }}>
          <b>{recording.cameraType}</b>
        </Typography>
        <Typography sx={{ marginLeft: sm ? '8px' : '10px', fontSize: '16px' }}>
          {recording.time}
          <AccessTimeIcon sx={{ marginLeft: '5px' }} />
        </Typography>
        <Typography
          sx={{ marginLeft: sm ? '8px' : '10px', fontSize: '16px' }}
        ></Typography>
      </Box>
      <Box
        sx={{
          width: '250px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isFailed ? (
          <>
            <Typography sx={{ fontSize: '16px', color: 'red' }}>
              Failed
            </Typography>
            {!showCraneIsOfflineMessage ? (
              <Button
                variant="outlined"
                size="medium"
                onClick={handleTryAgain}
                sx={{ color: 'red' }}
              >
                Try Again
                <ReplayIcon sx={{ marginLeft: '5px' }} />
              </Button>
            ) : (
              <>
                <Typography sx={{ fontSize: '16px', color: 'red' }}>
                  Crane is offline
                  <SignalWifiOffIcon sx={{ marginLeft: '4px' }} />
                </Typography>
              </>
            )}
          </>
        ) : isRequested ? (
          <>
            {isUploaded ? (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => setOpenVideoPlayer(true)}
                >
                  {!sm && 'Play'}{' '}
                  <PlayArrowIcon sx={{ marginLeft: sm ? '0px' : '5px' }} />
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleDownload}
                  style={{ width: '130px' }}
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <>
                      {!sm && 'Download'}{' '}
                      <DownloadIcon sx={{ marginLeft: sm ? '0px' : '5px' }} />
                    </>
                  )}
                </Button>
              </>
            ) : progress !== undefined &&
              progress !== null &&
              progress !== 0 ? (
              <RecordingProgressBar progress={progress} />
            ) : (
              <Typography sx={{ fontSize: '16px' }}>Queuing...</Typography>
            )}
          </>
        ) : (
          <Checkbox
            checked={selectedRecordings.includes(recording.id)}
            onChange={(e) =>
              setSelectedRecordings((prev: any) =>
                e.target.checked
                  ? [...prev, recording.id]
                  : prev.filter((id: any) => id !== recording.id),
              )
            }
          />
        )}
      </Box>
    </Stack>
  );
};

export default Recording;
