import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Popover,
  Paper,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Stack,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as storageHelper from '../../../utils/storageHelper';
import { USER } from '../../../variables/constants';
import { useNavigate } from 'react-router-dom';
import { Property } from '../../../components/dashboard/projectReport/content/styled';

import { parseUserAgent } from 'react-device-detect';
import { NotificationSettings } from '../../NotificationSettings';

const agent = parseUserAgent(window.navigator.userAgent);

const UserMenu = () => {
  const navigate = useNavigate();
  const user = storageHelper.getItem(USER);
  // const dispatch = useDispatch();
  //
  // const [projects, setProjects] = useState<any>([]);
  // const [cranes, setCranes] = useState<any>([]);
  // const [selectedProject, setSelectedProject] = useState<any>(
  //   storageHelper.getItem('ultrawis-selected-project') || '',
  // );
  // const [selectedCrane, setSelectedCrane] = useState<any>(
  //   storageHelper.getItem('ultrawis-selected-crane') || '',
  // );
  //
  // useEffect(() => {
  //   setProjects(user.Projects);
  //   setCranes(user.Cranes);
  // }, []);
  //
  // useEffect(() => {
  //   if (selectedProject && projects.length) {
  //     dispatch(projectCraneActions.setCurrentProject(selectedProject));
  //   }
  //   if (selectedCrane && cranes.length) {
  //     dispatch(projectCraneActions.setCurrentCrane(selectedCrane));
  //   }
  // }, [projects, cranes, selectedProject, selectedCrane]);
  //
  // const handleProjectChange = (event: any) => {
  //   const { value } = event.target;
  //   dispatch(projectCraneActions.setCurrentProject(value));
  //   setSelectedProject(value);
  //   setSelectedCrane('');
  //   storageHelper.setItem('ultrawis-selected-project', value);
  //   storageHelper.setItem('ultrawis-selected-crane', '');
  //   window.location.reload();
  // };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isOpenedFromMobile, setIsOpenedFromMobile] = useState(false);

  useEffect(() => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    if (isMobile) {
      setIsOpenedFromMobile(true);
    }
  }, []);

  const onSettings = (open: boolean) => {
    setSettingsOpen(open);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  const handleClickSettings = () => {
    onSettings(true);
    setAnchorEl(null);
  };

  const handleCloseSettings = () => {
    onSettings(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleClick} sx={{ p: 0 }}>
        <Avatar alt={user.firstName} sx={{ bgcolor: 'primary.main' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper sx={{ width: 320 }}>
          <Stack>
            <Stack sx={{ padding: 4 }} spacing={4}>
              <Stack spacing={4}>
                <Property
                  label="User"
                  value={`${user.firstName} ${user.lastName}`}
                  sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
                  textFontSize={12}
                />
                <Property
                  label="OS"
                  value={`${agent.os.name || ''} ${agent.os.version || ''}`}
                  sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
                  textFontSize={12}
                />
                {agent.browser.name && (
                  <Property
                    label="Browser"
                    value={`${agent.browser.name || ''} ${
                      agent.browser.version || ''
                    }`}
                    sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
                    textFontSize={12}
                  />
                )}
                {agent.device.type && (
                  <Property
                    label="Device"
                    value={`${agent.device.type || ''} ${
                      agent.device.name || ''
                    }`}
                    sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
                    textFontSize={12}
                  />
                )}
              </Stack>
            </Stack>
            {/*<Stack*/}
            {/*  spacing={2}*/}
            {/*  sx={{*/}
            {/*    padding: 4,*/}
            {/*    width: '100%',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Typography variant="smallRegular">select project</Typography>*/}
            {/*  <Select*/}
            {/*    value={selectedProject}*/}
            {/*    onChange={handleProjectChange}*/}
            {/*    fullWidth*/}
            {/*  >*/}
            {/*    {projects &&*/}
            {/*      projects.length > 0 &&*/}
            {/*      sortBy(projects, 'ProjectName').map((Project: any) => (*/}
            {/*        <MenuItem key={uuid()} value={Project.ID}>*/}
            {/*          {Project.ProjectName}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*  </Select>*/}
            {/*</Stack>*/}
            <List sx={{ padding: 0 }}>
              <Divider />
              {isOpenedFromMobile && (
                <ListItem disablePadding>
                  <ListItemButton onClick={handleClickSettings}>
                    <ListItemIcon>
                      <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Notification Settings" />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </List>
          </Stack>
        </Paper>
      </Popover>
      {/* <NotificationSettings open={settingsOpen} onClose={handleCloseSettings} /> */}
    </Box>
  );
};

export default UserMenu;
